<template>
  <action-dispatcher action="classes/get">
    <div class="view-admin-classes">
      <div class="d-flex aling-items-center justify-content-end mb-5">
        <router-link class="btn btn-primary" :to="{ name: 'admin-new-class' }">
          Nova Turma
        </router-link>
      </div>
      <div v-if="classes.length == 0" class="text-center my-4">
        <p class="text-secondary">
          Ainda não há turmas cadastradas
        </p>
      </div>
      <div
        v-else
        v-for="(c, i) in classes"
        :key="`class-${i}`"
        class="card d-flex flex-row align-items-center p-3 mb-4"
      >
        <router-link
          class="flex-fill"
          :to="{ name: 'admin-class', params: { id: c.id } }"
        >
          {{ c.name }}
        </router-link>
        <router-link
          :to="{ name: 'admin-edit-class', params: { id: c.id } }"
          class="btn m-0 p-0 mr-2"
        >
          <i class="icon-edit"></i>
        </router-link>
      </div>
    </div>
  </action-dispatcher>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("classes", ["classes"])
  }
};
</script>
<style lang="scss" scoped>
.view-admin-classes {
}
</style>
